import Modal from 'react-bootstrap/Modal';
import JoditEditor from "jodit-react";
import { Button } from 'react-bootstrap';
import { useFormik } from "formik";
import { getLabel } from '../../common/getLabel';
import { useRef, useState, useEffect, useMemo } from "react";
import { getEditorConfig, scrollToFirstError, stateListing } from '../../../common/common';
import { baseUrl } from '../../common/config';
import { deleteProfileImage, getDataUserProfiles, setupAdsData, setupProfileData, uploadProfileCoverImage, uploadProfileImage, deleteAdImage } from '../../services/manage-user-profiles/action';
import { useDispatch } from "react-redux"
import { trainerProfileSchema } from '../../common/validation';
import { ImageDeleteModal } from '../../common/imageDeleteModal';

const AddUpdateTrainer = (props) => {
    const { profileId, formshow, handleProfileViewClose, profileData, adData, editUserProfile, requestBody } = props;
    const [uploadImage, setUploadImage] = useState(null);
    const [uploadedProfileImage, setUploadedProfileImage] = useState(null);
    const [uploadCoverImage, setUploadCoverImage] = useState(null);
    const [profileImage, setProfileImage] = useState((profileData && profileData.image) ? profileData.image : []);
    const [profileCoverImage, setProfileCoverImage] = useState((profileData && profileData.cover_image) ? profileData.cover_image : []);
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const [imageDeleteId, setImageDeletedId] = useState();
    const [imageDeleteType, setImageDeleteType] = useState('');
    const handleCancelImage = (type) => {
        const setter = type === 1 ? setUploadedProfileImage : type === 2 ? setUploadCoverImage : setUploadImage;
        setter("");
    };
    const editor = useRef(null);
    const social_media = (profileData && profileData.social_media) ? JSON.parse(profileData.social_media) : '';
    const video_info = (profileData && profileData.video) ? JSON.parse(profileData.video) : '';
    const trainer_services_info = (profileData && profileData.trainer_services) ? JSON.parse(profileData.trainer_services) : '';
    const client = (profileData && profileData.client) ? JSON.parse(profileData.client) : '';
    // const trainer_testimonials_info = (profileData && profileData.trainer_testimonials) ? JSON.parse(profileData.trainer_testimonials) : '';
    const about = (profileData && profileData.about) ? JSON.parse(profileData.about) : '';
    const initialValues = {
        profile_id: (profileData) ? profileData.uprofile_id : '',
        user_id: (profileData) ? profileData.uprofile_user_id : '',
        profile_type: (profileData) ? profileData.uprofile_type : '3',
        first_name: (profileData) ? profileData.uprofile_fname : '',
        last_name: (profileData) ? profileData.uprofile_lname : '',
        phone_no: (profileData) ? profileData.uprofile_phone_no : '',
        website: (profileData) ? profileData.uprofile_website : '',
        company_name: (profileData) ? profileData.uprofile_company_name : '',
        style: (profileData) ? profileData.uprofile_style : '',
        email: (profileData) ? profileData.uprofile_email : '',
        address: (profileData) ? profileData.uprofile_address : '',
        city: (profileData) ? profileData.uprofile_city : '',
        state_code: (profileData) ? profileData.uprofile_state_code : '',
        zip: (profileData) ? profileData.uprofile_zip : '',
        social_media_facebook: (social_media && social_media.facebook) ? social_media.facebook : '',
        social_media_instagram: (social_media && social_media.instagram) ? social_media.instagram : '',
        social_media_twitter: (social_media && social_media.twitter) ? social_media.twitter : '',
        social_media_snapchat: (social_media && social_media.snapchat) ? social_media.snapchat : '',
        video: (video_info && video_info.url) ? video_info.url : '',
        trainer_services: trainer_services_info,
        client: client,
        // trainer_testimonials: trainer_testimonials_info,
        about: about,
        tads_id: (adData && adData.tads_id) ? adData.tads_id : '',
        ad_link: (adData && adData.tads_link) ? adData.tads_link : '',
        ad_title: (adData && adData.tads_title) ? adData.tads_title : '',
    };

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: trainerProfileSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postData = {
                profile_id: values.profile_id,
                user_id: values.user_id,
                type: initialValues.profile_type,
                first_name: values.first_name,
                last_name: values.last_name,
                company_name: values.company_name,
                email: values.email,
                phone_no: values.phone_no,
                website: values.website,
                style: "0",
                address: values.address,
                city: values.city,
                state_code: values.state_code,
                zip: values.zip,
                profile_info: {
                    social_media: {
                        facebook: values.social_media_facebook,
                        snapchat: values.social_media_snapchat,
                        instagram: values.social_media_instagram,
                        twitter: values.social_media_twitter,
                    },
                    about: values.about,
                    video: { "url": values.video },
                    trainer_services: values.trainer_services,
                    client: initialValues.client,
                    // trainer_testimonials: values.trainer_testimonials,
                }
            }
            try {
                const response = await setupProfileData(postData);
                if (response.success && response.data && response.data.profile_id && values.ad_title) {
                    const adsFormData = new FormData();
                    adsFormData.append("title", values.ad_title);
                    adsFormData.append("link", values.ad_link);
                    adsFormData.append("ads_id", values.tads_id);
                    adsFormData.append("profile_id", response.data.profile_id);
                    adsFormData.append("ads_image", uploadImage);
                    try {
                        await setupAdsData(adsFormData);
                    } catch (error) { }

                    if (uploadedProfileImage) {
                        const formData = new FormData();
                        formData.append("profile_id", response.data.profile_id);
                        formData.append("profile_image", uploadedProfileImage);
                        const profileImageResponse = await uploadProfileImage(formData);
                        if (profileImageResponse.success) {
                            setProfileImage(response.data);
                        }
                    }
                    if (uploadCoverImage) {
                        const formData = new FormData();
                        formData.append("profile_id", response.data.profile_id);
                        formData.append("profile_cover_image", uploadCoverImage);
                        const coverImageResponse = await uploadProfileCoverImage(formData);
                        if (coverImageResponse.success) {
                            setProfileCoverImage(response.data);
                        }
                    }
                }
                handleProfileViewClose();
                dispatch(getDataUserProfiles(requestBody));
            } catch (error) {
                console.log(error);
            }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const handleImageDeleteModalShow = (id, type) => {
        setImageDeletedId(id);
        setImageDeleteType(type);
        setImageDeleteModalShow(true);
    };
    const handleDeleteImage = async () => {
        try {
            if (imageDeleteType != 3) {
                const data = { profile_id: imageDeleteId, image_type: imageDeleteType };
                await deleteProfileImage(data);
            } else {
                await deleteAdImage({ "ad_id": imageDeleteId });
            }
            handleImageDeleteModalClose();
            document.querySelector(".image_list_" + imageDeleteType).remove();
        } catch (error) { }
    };

    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);

    const fieldRefs = useRef({});
    useEffect(() => {
        scrollToFirstError(formik, fieldRefs);
    }, [formik.errors, formik.touched, formik.isSubmitting]);

    const editorConfig = useMemo(() => getEditorConfig({
        readonly: !editUserProfile,
    }), []);

    return (
        <>
            <Modal
                show={formshow}
                onHide={handleProfileViewClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="exampleModalLabel">{getLabel("LBL_UPDATE_AN_ENTRY_IN_TRAINERS")}</h5>
                </Modal.Header>

                <form onSubmit={formik.handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4>{getLabel('LBL_Trainer_information')}</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_FIRST*")}</label>
                                            <input className="form-control" type="text" id="first_name" name="first_name" disabled={!editUserProfile} ref={el => fieldRefs.current.first_name = el} value={formik.values.first_name} onChange={handleChange} />
                                            <div>
                                                {formik.touched.first_name && formik.errors.first_name && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.first_name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_LAST*")}</label>
                                            <input className="form-control" type="text" id="last_name" name="last_name" disabled={!editUserProfile} ref={el => fieldRefs.current.last_name = el} value={formik.values.last_name} onChange={handleChange} />
                                            <div>
                                                {formik.touched.last_name && formik.errors.last_name && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.last_name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_BUSINESS*")}</label>
                                            <input className="form-control" type="text" id="company_name" name="company_name" disabled={!editUserProfile} ref={el => fieldRefs.current.company_name = el} value={formik.values.company_name} onChange={handleChange} />
                                            <div>
                                                {formik.touched.company_name && formik.errors.company_name && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.company_name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ADDRESS*")}</label>
                                            <input className="form-control" type="text" id="address" name="address" disabled={!editUserProfile} ref={el => fieldRefs.current.address = el} value={formik.values.address} onChange={handleChange} />
                                            <div>
                                                {formik.touched.address && formik.errors.address && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.address}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_CITY*")}</label>
                                            <input className="form-control" type="text" id="city" name="city" disabled={!editUserProfile} ref={el => fieldRefs.current.city = el} value={formik.values.city} onChange={handleChange} />
                                            <div>
                                                {formik.touched.city && formik.errors.city && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.city}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_STATE*")}</label>
                                            <select as="select" className="form-select" name="state_code" disabled={!editUserProfile} value={formik.values.state_code} ref={el => fieldRefs.current.state_code = el} aria-label="Default select example" onChange={handleChange}>
                                                <option value="">{getLabel('LBL_*State')}</option>
                                                {stateListing && stateListing().map((state, index) => (
                                                    <option key={index} value={state}>{state}</option>
                                                ))}
                                            </select>
                                            <div>
                                                {formik.touched.state_code && formik.errors.state_code && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.state_code}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ZIP*")}</label>
                                            <input className="form-control" type="text" id="zip" name="zip" disabled={!editUserProfile} ref={el => fieldRefs.current.zip = el} value={formik.values.zip} onChange={handleChange} />
                                            <div>
                                                {formik.touched.zip && formik.errors.zip && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.zip}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <label className="custom">{getLabel("LBL_PROFILE_IMAGE")}</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="profile_logo"
                                            onChange={(e) => setUploadedProfileImage(e.target.files[0])}
                                            disabled={!editUserProfile}
                                        />
                                        <div className="main-div">
                                            {uploadedProfileImage ? (
                                                <img
                                                    src={URL.createObjectURL(uploadedProfileImage)}
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                        borderRadius: "50%",
                                                    }}
                                                    onClick={() => handleCancelImage(1)}
                                                    alt="image-path"
                                                />
                                            ) : (
                                                <div className="show--image">
                                                    <div className={`image_list_1`}>
                                                        {profileImage && profileImage.length > 0 && (
                                                            <div>
                                                                <img
                                                                    src={`${baseUrl}/${profileImage[0].afile_physical_path}`}
                                                                    style={{
                                                                        height: "100px",
                                                                        width: "100px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                    alt="image-path"
                                                                />
                                                                {editUserProfile && (
                                                                    <input
                                                                        type="button"
                                                                        value="x"
                                                                        className="btn-rmv1 rmv"
                                                                        onClick={() => handleImageDeleteModalShow(formik.values.profile_id, 1)}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <label className="custom">{getLabel("LBL_COVER_IMAGE")}</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="profile_logo"
                                            onChange={(e) => setUploadCoverImage(e.target.files[0])}
                                            disabled={!editUserProfile}
                                        />
                                        <div className="main-div">
                                            {uploadCoverImage ? (
                                                <img
                                                    src={URL.createObjectURL(uploadCoverImage)}
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                        borderRadius: "50%",
                                                    }}
                                                    onClick={() => handleCancelImage(2)}
                                                    alt="image-path"
                                                />
                                            ) : (
                                                <div className="show--image">
                                                    <div className={`image_list_2`}>
                                                        {profileCoverImage && profileCoverImage.length > 0 && (
                                                            <div>
                                                                <img
                                                                    src={`${baseUrl}/${profileCoverImage[0].afile_physical_path}`}
                                                                    style={{
                                                                        height: "100px",
                                                                        width: "100px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                    alt="image-path"
                                                                />
                                                                {editUserProfile && (
                                                                    <input
                                                                        type="button"
                                                                        value="x"
                                                                        className="btn-rmv1 rmv"
                                                                        onClick={() => handleImageDeleteModalShow(formik.values.profile_id, 2)}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_EMAIL")}*</label>
                                            <input className="form-control" type="text" id="email" name="email" disabled={!editUserProfile} ref={el => fieldRefs.current.email = el} value={formik.values.email} onChange={handleChange} />
                                        </div>
                                        <div>
                                            {formik.touched.email && formik.errors.email && (
                                                <p style={{ color: "#b94a48" }}>{formik.errors.email}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_PHONE")}*</label>
                                            <input className="form-control" type="text" id="phone_no" name="phone_no" disabled={!editUserProfile} value={formik.values.phone_no} onChange={handleChange} />
                                            <div>
                                                {formik.touched.phone_no && formik.errors.phone_no && (
                                                    <p style={{ color: "#b94a48" }}>{formik.errors.phone_no}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_URL")}</label>
                                            <input className="form-control" type="text" id="website" name="website" disabled={!editUserProfile} value={formik.values.website} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Social_accounts')}</h4>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_TWITTER")}</label>
                                            <input className="form-control" type="text" id="social_media_twitter" name="social_media_twitter" disabled={!editUserProfile} value={formik.values.social_media_twitter} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_INSTAGRAM")}</label>
                                            <input className="form-control" type="text" id="social_media_instagram" name="social_media_instagram" disabled={!editUserProfile} value={formik.values.social_media_instagram} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_SNAPCHAT")}</label>
                                            <input className="form-control" type="text" id="social_media_snapchat" name="social_media_snapchat" disabled={!editUserProfile} value={formik.values.social_media_snapchat} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_FACEBOOK")}</label>
                                            <input className="form-control" type="text" id="social_media_facebook" name="social_media_facebook" disabled={!editUserProfile} value={formik.values.social_media_facebook} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_About_/_bio')}</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_ABOUT")}</label>
                                            <textarea className="form-control" id="about" name="about" rows="3" disabled={!editUserProfile} value={formik.values.about} onChange={handleChange} ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Services_&_Clients')}</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_SERVICE")}</label>
                                            <JoditEditor
                                                ref={editor}
                                                value={formik.values.trainer_services}
                                                config={editorConfig}
                                                tabIndex={1}
                                                onBlur={(newContent) => { formik.handleChange({ target: { name: "trainer_services", value: newContent } }); }}
                                                onChange={newContent => { }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Video_embed')}</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_VIDEO")}</label>
                                            <textarea className="form-control" id="video" name="video" rows="3" disabled={!editUserProfile} value={formik.values.video} onChange={handleChange} ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>{getLabel('LBL_Ads')}</h4>
                                    </div>
                                    <div className="col-lg-12">
                                        <label className="custom">{getLabel("LBL_AD_IMAGE")}</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="inputGroupFile03"
                                            onChange={(e) => setUploadImage(e.target.files[0])}
                                            disabled={!editUserProfile}
                                        />
                                        <div className="main-div">
                                            {uploadImage ? (
                                                <img
                                                    src={URL.createObjectURL(uploadImage)}
                                                    style={{
                                                        height: "100px",
                                                        width: "100px",
                                                        borderRadius: "50%",
                                                    }}
                                                    onClick={() => handleCancelImage(0)}
                                                    alt="image-path"
                                                />
                                            ) : (
                                                <div className="show--image">
                                                    <div className={`image_list_3`}>
                                                        {adData && adData.tads_image && Object.keys(adData.tads_image).length > 0 && (
                                                            <div>
                                                                <img
                                                                    src={`${baseUrl}/${adData.tads_image[0].afile_physical_path}`}
                                                                    style={{
                                                                        height: "100px",
                                                                        width: "100px",
                                                                        borderRadius: "50%",
                                                                    }}
                                                                    onClick={() => handleCancelImage(3)}
                                                                    alt="image-path"
                                                                />
                                                                {editUserProfile && (
                                                                    <input
                                                                        type="button"
                                                                        value="x"
                                                                        className="btn-rmv1 rmv"
                                                                        onClick={() => handleImageDeleteModalShow(adData.tads_id, 3)}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_AD_TITLE")}</label>
                                            <input className="form-control" type="text" id="ad_title" name="ad_title" disabled={!editUserProfile} value={formik.values.ad_title} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="input">{getLabel("LBL_AD_LINK")}</label>
                                            <input className="form-control" type="text" id="ad_link" name="ad_link" disabled={!editUserProfile} value={formik.values.ad_link} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    {editUserProfile && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleProfileViewClose}>
                                {getLabel("LBL_Close")}
                            </Button>
                            <Button variant="primary" type="submit">{getLabel("LBL_Submit")}</Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
            <ImageDeleteModal
                imageDeleteModalshow={imageDeleteModalshow}
                handleImageDeleteModalClose={handleImageDeleteModalClose}
                handleDeleteImage={handleDeleteImage}
            />
        </>
    )
}

export default AddUpdateTrainer
