import React, { useRef, useEffect, useMemo } from 'react'
import JoditEditor from "jodit-react";
import { baseUrl } from '../../../common/config';
import { useFormik } from "formik";
import { setupUserProfileInfo } from '../../../services/account/action';
import { useNavigate } from "react-router-dom";
import { getEditorConfig } from '../../../../common/common';
// import { getLabel } from '../../../../common/getLabel'

const ClientServices = (props) => {
    const { profileData, profileId, useDynamicFields } = props;
    const { fields: clientsField, setFields: setClientsFields, addField: addClientsFields, removeField: removeClientsField, handleChange: handleClientsField } = useDynamicFields({ client_name: '', client_link: '' });
    const editor = useRef(null);

    const trainer_services_info = (profileData && profileData.trainer_services) ? JSON.parse(profileData.trainer_services) : '';
    // const trainer_testimonials_info = (profileData && profileData.trainer_testimonials) ? JSON.parse(profileData.trainer_testimonials) : '';
    const social_media = (profileData && profileData.social_media) ? JSON.parse(profileData.social_media) : '';
    const video_info = (profileData && profileData.video) ? JSON.parse(profileData.video) : '';
    const about = (profileData && profileData.about) ? JSON.parse(profileData.about) : '';
    const initialValues = {
        trainer_services: trainer_services_info,
        // trainer_testimonials: trainer_testimonials_info,
        social_media: social_media,
        about: about,
        video: video_info,
    };

    useEffect(() => {
        if (profileData && profileData.client && profileData.client.length > 0) {
            setClientsFields(JSON.parse(profileData.client));
        }
    }, [profileData]);

    const navigate = useNavigate("")

    const filteredClientField = (clientsField) ? clientsField.filter(item => item.client_name !== '' || item.client_link !== '') : [];

    const formik = useFormik({
        initialValues: initialValues,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postData = {
                // user_id: String(user_id),
                profile_id: profileId,
                profile_info:
                {
                    client: filteredClientField,
                    trainer_services: values.trainer_services,
                    // trainer_testimonials: values.trainer_testimonials,
                    social_media: initialValues.social_media,
                    // trainer_URL: initialValues.trainer_URL,
                    about: initialValues.about,
                    video: initialValues.video,
                }
            }
            try {
                const response = await setupUserProfileInfo(postData);
                if (response === true) {
                    navigate('/qbhl-admin');
                }
            } catch (error) {
                console.log(error);
            }
        },
    });

    const editorConfig = useMemo(() => getEditorConfig(), []);

    return (
        <>
            <h4>Services & Clients</h4>
            <form onSubmit={formik.handleSubmit}>
                <div className="row bg-white-tranier mb-5">
                    <p className="heading-descr">Description /Overview</p>
                    <p><i className='bx bx-error'></i> When coyping and pasting from a Word document, please turn on the "Paste
                        as Text" button(far right) to prevent formatting errors.</p>
                    <div className="col-lg-12">
                        <div className="form-check form-switch">
                            <JoditEditor
                                ref={editor}
                                value={formik.values.trainer_services}
                                config={editorConfig}
                                tabIndex={1}
                                onBlur={(newContent) => { formik.handleChange({ target: { name: "trainer_services", value: newContent } }); }}
                                onChange={newContent => { }}
                            />
                        </div>
                    </div>

                    <div className="col-lg-12 mt-4">
                        <div className="bio">
                            <label>Clients</label>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="row">
                            {clientsField && clientsField.map((field, index) => (
                                <React.Fragment key={index}>
                                    <div className="col-lg-5">
                                        <div className="from-group">
                                            <input type="text" className="form-control" name={`client_name[${index}]`} value={field.client_name} onChange={(e) => handleClientsField(index, 'client_name', e.target.value)} placeholder="Name" />
                                        </div>
                                    </div>
                                    <div className="col-lg-5" key={index}>
                                        <div className="from-group">
                                            <input type="text" className="form-control" name={`client_link[${index}]`} value={field.client_link} onChange={(e) => handleClientsField(index, 'client_link', e.target.value)} placeholder="Link" />
                                        </div>
                                    </div>
                                    {index > 0 && (
                                        <div className="col-lg-1 required_inp">
                                            <div className="from-group">
                                                <input type="button" className="inputRemove" value="X" onClick={() => removeClientsField(index)}></input>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="add-more">
                            <button type="button" className="add_input" onClick={() => addClientsFields()}><i className='bx bx-plus'></i> Add More</button>
                        </div>
                    </div>


                    {/* <h4 className="testimonials-heading">Testimonials</h4>
                    <p><i className='bx bx-error'></i> When coyping and pasting from a Word document, please turn on the "Paste
                        as Text" button(far right) to prevent formatting errors.</p>
                    <div className="col-lg-12">
                        <div className="form-check form-switch">
                            <JoditEditor
                                ref={editor}
                                value={formik.values.trainer_testimonials}
                                config={{
                                    height: 300,
                                    toolbarAdaptive: false,
                                    uploader: {
                                        insertImageAsBase64URI: true,
                                        imagesExtensions: ["jpg", "jpeg", "png", "gif"],
                                        filesVariableName: "blockImages",
                                        path: baseUrl,
                                        prepareData: (formdata) => {
                                            formdata.append("additionalParam", "value");
                                            return formdata;
                                        },
                                    },
                                }}
                                tabIndex={1}
                                onBlur={(newContent) => { formik.handleChange({ target: { name: "trainer_testimonials", value: newContent } }); }}
                                onChange={newContent => { }}
                            />
                        </div>
                    </div> */}

                    <div className="col-lg-12 mb-5">
                        <div className="save-btn">
                            <button type="submit" className="btn btn-primary">Save Changes</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ClientServices
