import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useMemo, useRef, useState } from "react";
import { contentBlockSchema } from "../../common/validation";
import { useFormik } from "formik";
import { baseUrl } from "../../common/config";
import { getDataContentBlocks, updateContentBlock, deleteContentBlockImage, deleteUserImage } from "../../services/content-block/action";
import { useDispatch } from "react-redux";
import { getLabel } from "../../common/getLabel";
import { ImageDeleteModal } from "../../common/imageDeleteModal";
import { getEditorConfig } from "../../../common/common";

const AddUpdateContentBlock = (props) => {
  const { formshow, handleFormClose, contentBlockData, editContentBlock } = props;
  const [blockImages, setblockImages] = useState(null);
  const [imageDeleteId, setImageDeletedId] = useState();
  const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
  const editor = useRef(null);
  const fileInputRef = useRef(null);

  const imageId = contentBlockData && contentBlockData.images && Object.keys(contentBlockData.images).length > 0 && contentBlockData.images[0].afile_record_id
  const fileId = contentBlockData && contentBlockData.images && Object.keys(contentBlockData.images).length > 0 && contentBlockData.images[0].afile_id;
  const dispatch = useDispatch();

  const initialValues = {
    cb_id: (contentBlockData) ? contentBlockData.cb_id : '',
    cb_title: (contentBlockData) ? contentBlockData.cb_title : '',
    cb_status: (contentBlockData) ? contentBlockData.cb_status : '',
    cb_content: (contentBlockData) ? contentBlockData.cb_content : '',
  };


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: contentBlockSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("cb_title", values.cb_title);
      formData.append("cb_content", values.cb_content);
      formData.append("block_image", blockImages);
      formData.append("cb_status", values.cb_status);
      if (typeof initialValues.cb_id === "number") {
        formData.append("cb_id", initialValues.cb_id);
        formData.append("afile_id", fileId);
      }
      if (editContentBlock) {
        try {
          await updateContentBlock(formData);
          handleFormClose();
          dispatch(getDataContentBlocks());
        } catch (error) { }
      }
    },
  });

  const handleChange = (e) => {
    // const { name, value, files } = e.target;
    formik.handleChange(e);
  };

  const handleCancelImage = () => {
    setblockImages("");
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleImageDeleteModalShow = (id) => {
    setImageDeletedId(id);
    setImageDeleteModalShow(true);
  };

  const handleDeleteImage = async () => {
    try {
      const data = { afile_id: imageDeleteId };
      await deleteContentBlockImage(data);
      handleImageDeleteModalClose();
      const Name = "image_list_" + imageDeleteId;
      document.querySelector("." + Name).remove();
    } catch (error) { }
  };

  const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);

  const editorConfig = useMemo(() => getEditorConfig({
      readonly: !editContentBlock,
  }), []);

  return (
    <>
      <Modal
        show={formshow}
        onHide={handleFormClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {getLabel("LBL_Update_Blocks")}
          </h5>
        </Modal.Header>

        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="input">{getLabel("LBL_Title*")}</label>
                      <input
                        className="form-control"
                        type="text"
                        id="cb_title"
                        name="cb_title"
                        onChange={handleChange}
                        value={formik.values.cb_title}
                        placeholder={getLabel("LBL_Enter_Title_Name")}
                        disabled={!editContentBlock}
                      />
                      <div>
                        {formik.touched.cb_title && formik.errors.cb_title && (
                          <p style={{ color: "#b94a48" }}>{formik.errors.cb_title}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <label className="form-label" htmlFor="basic-default-message">
                    {getLabel("LBL_Page_Content")}
                  </label>
                  <JoditEditor
                    ref={editor}
                    tabIndex={1}
                    value={formik.values.cb_content}
                    config={editorConfig}
                    onBlur={(value) => formik.setFieldValue("cb_content", value)}
                    onChange={newContent => { }}

                  />


                  <div className="input-group file col-md-6">
                    <label className="custom">
                      {getLabel("LBL_Background_Image")}
                    </label>
                    <input
                      ref={fileInputRef}
                      className="form-control"
                      type="file"
                      id="image"
                      name="image"
                      onChange={(e) => setblockImages(e.target.files[0])}
                      disabled={!editContentBlock}
                    />
                    <label
                      className="input-group-text"
                      htmlFor="inputGroupFile03"
                    >
                      {getLabel("LBL_Upload")}
                    </label>
                  </div>

                  <div className="main-div">
                    {blockImages ? (
                      <img
                        src={URL.createObjectURL(blockImages)}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                        onClick={handleCancelImage}
                        alt="image-path ist"
                      />
                    ) : (
                      <div className="show--image">
                        <div className={`image_list_${imageId}`}>
                          {contentBlockData.images &&
                            Object.keys(contentBlockData.images).length > 0 && (
                              <div>
                                <img
                                  src={`${baseUrl}/${contentBlockData.images[0].afile_physical_path}`}
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "50%",
                                  }}
                                  onClick={handleCancelImage}
                                  alt="image-path"
                                />
                                {editContentBlock && (
                                  <input
                                    type="button"
                                    value="x"
                                    className="btn-rmv1 rmv"
                                    onClick={() =>
                                      handleImageDeleteModalShow(imageId)
                                    }
                                  />
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="inputState">
                      {getLabel("LBL_Status*")}
                    </label>
                    <select
                      as="select"
                      className="form-select"
                      aria-label="Default select example"
                      id="cb_status"
                      name="cb_status"
                      onChange={handleChange}
                      value={formik.values.cb_status}
                      disabled={!editContentBlock}
                    >
                      <option value="">{getLabel("LBL_Choose")}</option>
                      <option value="1">{getLabel("LBL_Active")}</option>
                      <option value="0">{getLabel("LBL_InActive")}</option>
                    </select>
                    <div>
                      {formik.touched.cb_status && formik.errors.cb_status && (
                        <p style={{ color: "#b94a48" }}>{formik.errors.cb_status}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFormClose}>
              {getLabel("LBL_Close")}
            </Button>
            {editContentBlock && (
              <Button variant="primary" type="submit">
                {getLabel("LBL_Submit")}
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      <ImageDeleteModal
        imageDeleteModalshow={imageDeleteModalshow}
        handleImageDeleteModalClose={handleImageDeleteModalClose}
        handleDeleteImage={handleDeleteImage}
      />
    </>
  );
};
export default AddUpdateContentBlock;
